<template>
    <div class="permission-page">
        <router-link class="back-previous-screen" to="/bank/settings/bank-settings/permission-groups">
            {{ "< " + $t("Back to [Previous Screen]", { previousScreen: $t("Bank Settings") }) }}
        </router-link>

        <CustomLoader v-if="loading" height="400px" />

        <template v-else>
            <PermissionGroupForm
              class="mb-7"
              ref="permissionGroupForm"
              :title="$t('Edit Permission Group')"
              :permission-data="permissionData"
              :submitted="submitted"
              :countries-list="bankCountriesList"
              :customers-list="bankCustomersList"
              :product-areas-list="bankProductAreasList"
              @onFormChange="onFormChange"
            />

            <PermissionGroupUsers
                ref="permissionGroupUsers"
                :users-data-list="bankUsersData"
                :selected-users="permissionData.users"
                @onFormChange="onUsersFormChange"
            />

            <div class="permission-page__footer">
                <button class="main-btn-outline btn" @click="handleCancel">{{ $t('Cancel') }}</button>

                <button
                    :data-kt-indicator="submitLoading ? 'on' : 'off'"
                    :disabled="submitLoading || !isFormChanged"
                    class="btn main-btn"
                    @click="handleSave">
                    <span class="indicator-label"> {{ $t("Save") }} </span>

                    <span class="indicator-progress">
                        {{ $t("pleaseWait") }}
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
import PermissionGroupForm from "@/buying-teams/pages/bank/settings/bank/components/permission/PermissionGroupForm.vue";
import PermissionGroupUsers from "@/buying-teams/pages/bank/settings/bank/components/permission/PermissionGroupUsers.vue";
import store from "@/store";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader.vue";
import NotificationService from "@/buying-teams/services/NotificationService";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";
import { filterAllowedCustomers } from "@/store/models/bank/permissions/BankPermissionGroup";

export default {
    name: "EditPermissionGroup",
    components: {
        CustomLoader,
        PermissionGroupUsers,
        PermissionGroupForm
    },
    computed: {
        bankData() {
            return store.getters.bank;
        },
        currentUser() {
            return store.getters.currentUser;
        },
        bankProductAreasList() {
            return store.getters.getBankProductAreas;
        },
        bankCustomersList() {
            return store.getters.getBankCustomersList;
        },
        bankCustomersListIds() {
            return this.bankCustomersList.map(c => c.id);
        },
        bankCountriesList() {
            return store.getters.getBankCountries;
        },
        isFormChanged() {
            return JSON.stringify(this.permissionGroupForm) !== this.permissionGroupFormInitData;
        }
    },
    data() {
        return {
            groupId: null,
            loading: true,
            permissionData: null,
            submitted: false,
            submitLoading: false,
            usersDataLoading: true,
            bankUsersData: [],
            permissionGroupForm: null,
            permissionGroupFormInitData: null,
        }
    },
    beforeMount() {
        if (this.$route.params.id) {
            this.groupId = Number(this.$route.params.id);
        } else {
            this.$router.push({ name: 'permission-groups' });
        }
    },
    mounted() {
        if (this.currentUser && !this.currentUser.can(BankUserPermissionTypeEnum.CHANGE_USER_GROUP_PERMISSIONS)) {
            this.$router.push('/bank/settings/bank-settings/permission-groups');
        }

        setCurrentPageBreadcrumbs({
            title: this.bankData.name,
            logo: this.bankData.icon_path
        });

        Promise.all([
            this.handleGetUsersList(),
            this.getPermissionsGroup(),
        ]).finally(() => {
            this.loading = false;
        })
    },
    methods: {
        async getPermissionsGroup() {
            await store.dispatch('getPermissionsGroupById', this.groupId).then(res => {
                this.permissionData = {
                    group_name: res.name,
                    permissions: res.functionalPermissions,
                    countries: res.allowedCountries,
                    product_areas: res.allowedProductAreas,
                    customers: filterAllowedCustomers(res.blockedCustomers, this.bankCustomersListIds),
                    users: res.includedUsers,
                };
                this.permissionGroupFormInitData = JSON.stringify(this.permissionData);
            })
        },
        async handleGetUsersList() {
            await store.dispatch('getUsersList').then(res => {
                this.bankUsersData = res;
            })
        },
        handleCancel() {
            this.$router.push({ name: 'permission-groups' });
        },
        onFormChange(newForm) {
            if (this.permissionGroupForm && Object.keys(this.permissionGroupForm).length) {
                Object.keys(newForm).forEach(key => {
                    this.permissionGroupForm[key] = newForm[key];
                });
            } else {
                this.permissionGroupForm = { ...newForm };
            }
        },
        onUsersFormChange(users) {
            if (this.permissionGroupForm && Object.keys(this.permissionGroupForm).length) {
                this.permissionGroupForm.users = users;
            }
        },
        handleSave() {
            const permissionGroupForm = this.$refs.permissionGroupForm;
            this.submitted = true;

            if (permissionGroupForm.isFormValid) {
                this.submitLoading = true;
                const payload = { ...this.permissionGroupForm, group_id: this.groupId };
                payload.customers = filterAllowedCustomers(this.permissionGroupForm.customers, this.bankCustomersListIds);

                store.dispatch('editPermissionsGroup', payload)
                    .then(() => {
                        NotificationService.swalNotify({ confirmButtonText: this.$t("OK") });
                        this.$router.push({ name: 'permission-groups' });
                    })
                    .finally(() => {
                        this.submitLoading = false;
                    });
            } else {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth", });
            }
        }
    }
}
</script>

<style lang="scss">
.permission-page {
    &__footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 34px;
        padding-right: 40px;
        padding-bottom: 5px;
    }
}
</style>
